.content {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.page {
  background: url(/public/Speckles.png);
  font-family: Norwester;
  background-size: contain;
  color: #ffffff;
}
