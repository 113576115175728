.page {
  width: 73%;
  margin: 0 auto;
  text-align: center;
}

.header {
  margin: 100px auto;
}

.photoCard {
  margin-bottom: 100px;
}

.iframeContainer {
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 100px;
}

.iframeFiller {
  padding-top: 56.25%;
}

.text {
  margin-bottom: 50px;
}

.contact {
  margin-bottom: 100px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 190px;
}

@media only screen and (max-width: 769px) {
  .header {
    margin-top: 30px;
  }
  .page {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .buttonContainer {
    margin-bottom: 90px;
  }
}
