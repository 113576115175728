.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 36px;
  background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 6px;
  font-family: Norwester;
  cursor: pointer;
}

.text {
  cursor: pointer;
  font-family: Norwester;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
