.page {
  width: 73%;
  margin: 0 auto;
  text-align: center;
}

.header {
  margin: 100px 51px auto;
}

.locationInfo {
  margin-bottom: 100px;
}

.info {
  display: flex;
  gap: 200px;
  width: 100%;
  margin-bottom: 110px;
  flex-wrap: wrap;
}

.left {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mapContainer {
  margin: auto;
  width: 90%;
  height: 500px;
  margin-bottom: 100px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 190px;
}

@media only screen and (max-width: 769px) {
  .mapContainer {
    height: 200px;
  }
  .header {
    margin-top: 30px;
  }
  .page {
    width: 90%;
  }
  .info {
    gap: 90px;
  }
  .buttonContainer {
    margin-bottom: 90px;
  }
}
