.card {
  width: 100%;
  max-width: 400px;
  background: #171820;
  border-radius: 16px;
  padding: 26px 26px 20px 20px;
  font-family: Norwester;
}

.heading {
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
}

.text {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

@media only screen and (max-width: 769px) {
  .heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }

  .text {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
}

.pink {
  background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}
