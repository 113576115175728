.page {
  width: 73%;
  margin: 0 auto;
  text-align: center;
}

.header {
  margin: 100px auto;
}

.text {
  margin-bottom: 170px;
}

.skills {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 220px;
}

.secondHeader {
  margin-bottom: 100px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 100px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 190px;
}

@media only screen and (max-width: 1400px) {
  .skills {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 769px) {
  .header {
    margin-top: 30px;
  }
  .page {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .cards {
    gap: 30px
  }
  .skills {
    margin-bottom: 90px;
  }
  .text {
    margin-bottom: 90px;
  }
  .buttonContainer {
    margin-bottom: 90px;
  }
}
