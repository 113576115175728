.card {
  font-family: Norwester;
  width: 100%;
  max-width: 370px;
  background: #171820;
  border-radius: 12px;
  padding: 28px;
  display: flex;
  padding: 10px;
  gap: 5px;
}

.photoContainer {
  border-radius: 3px;
  background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
  flex-basis: 36px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
}

.text {
  line-height: 28px;
  color: #a6a9b8;
}

.textContainer {
  text-align: center;
}

@media only screen and (max-width: 769px) {
  .heading {
    font-size: 16px;
    line-height: 20px;
  }

  .text {
    font-size: 12px;
    line-height: 20px;
  }
}
