@import './fonts/fonts.css';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  background-color: black;
}

body {
  width: 100% !important;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

h1 {
  cursor: default;
  font-size: 200px;
  font-weight: 400;
  line-height: 240px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 2;
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(172, 172, 172, 0.8),
      rgb(255, 255, 255, 0.8) 70.71%
    ),
    linear-gradient(
      129deg,
      rgba(171, 171, 171, 0.8),
      rgba(255, 255, 255, 0.8) 70.71%
    );
  background-clip: text;
  color: transparent;
}

h2 {
  cursor: default;
  font-size: 64px;
  font-weight: 400;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 2;
  position: relative;
}

h3 {
  cursor: default;
  font-size: 48px;
  font-weight: 400;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: center;
}

p {
  cursor: default;
  margin: 0;
  padding: 0;
  font-size: 34px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
}

/* ПК и некоторые большие ноутбуки */
@media only screen and (min-width: 1025px) {
  h1 {
    font-size: 200px;
    line-height: 240px;
  }

  h2 {
    font-size: 64px;
    line-height: 77px;
  }

  h3 {
    font-size: 48px;
    line-height: 76px;
  }

  p {
    font-size: 34px;
    line-height: 41px;
  }
}

/* Планшеты в горизонтальной ориентации и некоторые небольшие ноутбуки */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  h1 {
    font-size: 150px;
    line-height: 180px;
  }

  h2 {
    font-size: 48px;
    line-height: 58px;
  }

  h3 {
    font-size: 36px;
    line-height: 57px;
  }

  p {
    font-size: 24px;
    line-height: 29px;
  }
}

/* Мобильные устройства (планшеты в вертикальной ориентации) */
@media only screen and (max-width: 470px) {
  h1 {
    font-size: 60px;
    line-height: 70px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  h3 {
    font-size: 24px;
    line-height: 38px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
}

/* Мобильные устройства (телефоны) */
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 100px;
    line-height: 120px;
  }

  h2 {
    font-size: 32px;
    line-height: 38px;
  }

  h3 {
    font-size: 24px;
    line-height: 38px;
  }

  p {
    font-size: 18px;
    line-height: 22px;
  }
}
