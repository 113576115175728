.swiper {
  width: 100%;
  height: 100%;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.swiper-slide:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 64, 64, 0.5) 0%,
    rgba(172, 0, 253, 0.2) 10%
  );
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-button-next {
  color: white;
}

.swiper-button-prev {
  color: white;
}

.ant-modal-content {
  background: url(/public/Speckles.png);
}
