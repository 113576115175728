.card {
  max-width: 500px;
  font-family: Norwester;
  width: 100%;
  border-radius: 16px;
  padding: 49px;
  padding: 50px 70px;
  cursor: pointer;
}

.card:hover {
  background: linear-gradient(90deg, rgba(255, 64, 64, 0.5) 0%, rgba(172, 0, 253, 0.2) 10%);
}
.heading {
  border-top: 0.8px solid #bfbfbf;
  margin: 25px;
  padding: 5px;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
}

.textContainer {
  text-align: center;
  width: 66%;
}

.img {
  width: 100%;
}

/* Мобильные устройства (планшеты в вертикальной ориентации) */
@media only screen and (max-width: 1440px) {
  .heading {
    margin-bottom: 15px;
    font-size: 32px;
    line-height: 40px;
  }
  .text {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 1200px) {
  .heading {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }
  .text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 1000px) {
  .heading {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }
  .text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 800px) {
  .heading {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 40px;
  }
  .text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 650px) {
  .heading {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .heading {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 40px;
  }
}
