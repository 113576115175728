.card {
  font-family: Norwester;
  width: 100%;
  background: #171820;
  border-radius: 16px;
  padding: 49px;
  display: flex;
  /*   flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  gap: 100px;
  padding: 50px 70px;
}

.heading {
  border-bottom: 0.8px solid #bfbfbf;
  margin-bottom: 25px;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
}

.textContainer {
  text-align: center;
  width: 66%;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
.img {
  width: 100%;
}

/* Мобильные устройства (планшеты в вертикальной ориентации) */
@media only screen and (max-width: 1440px) {
  .card {
    gap: 70px;
  }
  .heading {
    margin-bottom: 15px;
    font-size: 32px;
    line-height: 40px;
  }
  .text {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 1200px) {
  .card {
    padding: 30px;
  }
  .heading {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }
  .text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    padding: 20px;
    gap: 40px;
  }
  .heading {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }
  .text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 800px) {
  .card {
    padding: 20px;
    gap: 20px;
  }
  .heading {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 40px;
  }
  .text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 650px) {
  .card {
    flex-direction: column;
  }
  .heading {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 40px;
  }
  .text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 400px) {
  .heading {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 40px;
  }
  .text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0em;
  }
}
