.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.clickable {
  cursor: pointer;
}
.text {
  font-family: Norwester;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #bfbfbf;
}
