.page {
  width: 66%;
  margin: 0 auto;
  text-align: center;
}

.mainImgContainer {
  position: relative;
  margin-bottom: 83px;
}

.mainHeadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainHeading:hover {
  background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

.mainImgTexts {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.bottomParagraph {
  margin-bottom: 60px;
}

.img {
  width: 100%;
}

.mainImg {
  padding-top: 120px;
}

.subHeader {
  margin-bottom: 99px;
}

.secondHeading {
  margin-bottom: 20px;
}
.balls {
  padding-bottom: 88px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 190px;
}

.iframeContainer {
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 100px;
}

.iframeFiller {
  padding-top: 56.25%;
}

/* Планшеты в горизонтальной ориентации и некоторые небольшие ноутбуки */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .mainImg {
    padding-top: 100px;
  }

}

@media only screen and (max-width: 769px) {
  .mainImg {
    padding-top: 60px;
  }
  .mainHeading {
    font-size: 80px;
    line-height: 100px;
  }
  .page {
    width: 90%;
  }
  .buttonContainer {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 470px) {
  .mainImg {
    padding-top: 35px;
  }
}
