.container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 22px 64px;
  margin: 0 auto;
}

.link {
  color: #bfbfbf;
  cursor: pointer;
  min-width: 188px;
  height: 36px;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Norwester";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}

.link:hover {
  color: #f5f7f5;
}

.active {
  background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

.active:hover {
  background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

.goods {
  color: #bfbfbf;
  animation-name: color;
  animation-duration: 4s; /* Increased duration */
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; /* Added to create smoother transition */
}

@keyframes color {
  0% {
    color: #bfbfbf;
  }
  25% {
    color: #bfbfbf; /* Added keyframe */
  }
  50% {
    background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 0);
  }
  75% {
    background: linear-gradient(90deg, #ff4040 0%, #ac00fd 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 0);
  }
  100% {
    color: #bfbfbf;
  }
}
