.container {
  width: 100%;
  display: flex;
  /*   height: 110px; */
  background: #151515;
}

.left {
  width: 33%;
  display: flex;
  padding: 20px;
  justify-content: center;
}

.right {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}

.logoContainer {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 20px 0 0;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}

.logoText {
  cursor: pointer;
  color: #222858;
  margin-left: -20px;
}

@media only screen and (max-width: 769px) {
  .container {
    padding: 20px 10px;
  }
  .left {
    display: none;
  }
}
